<template>
  <div class="pt-10 md:pt-20 pb-0 md:pb-20">
    <div
      class="md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
    >
      <div
        class="w-full text-left text-textBlack flex flex-col md:flex-row items-start justify-between"
      >
        <Warning warningBg="warningBg" v-if="showWarning">
          <p>Wrong email address or password</p>
        </Warning>
        <!-- <img
          class="md:h-72 m-auto"
          src="@/assets/images/icons/neo-agent.jpg"
          alt="neo-agent.jpg"
        /> -->
        <div class="mx-auto mt-4 md:mt-0 text-center">
          <h1 class="mb-4 text-4xl font-semibold text-primary">Agent Login</h1>
          <p class="w-full text-base text-textBlack text-justify">
            If you're an authorized neo agent, please enter your login and
            password below.
          </p>
          <div class="w-full md:w-7/12 mx-auto mt-4 flex flex-col">
            <div class="w-10/12 md:w-full flex justify-between">
              <label class="pr-2" for="email">Email</label>
              <input
                type="email"
                name="email"
                v-model="email"
                class="px-2 py-1 text-sm border border-softGrey"
              />
            </div>
            <div class="w-10/12 md:w-full mt-4 flex justify-between">
              <label class="pr-2" for="password">Password</label>
              <input
                type="password"
                name="password"
                v-model="password"
                class="px-2 py-1 text-sm border border-softGrey"
              />
            </div>
            <div
              class="w-max mx-auto mt-8 px-10 py-1 text-center bg-secondary text-softWhite hover:bg-softGrey hover:text-secondary cursor-pointer"
              v-if="!showLoader"
              @click="submitForm"
            >
              Login
            </div>
            <div v-else>
              <Loader />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../components/loader/Loader";
import Warning from "../components/warning/Warning";

export default {
  components: {
    Loader,
    Warning,
  },
  data() {
    return {
      errors: [],
      errorPassword: [],
      email: null,
      password: null,
      showLoader: false,
      showWarning: false,
      warningBg: false,
    };
  },
  computed: {
    handleLoginError() {
      return this.$store.state.stateError401;
    },
  },
  methods: {
    submitForm(e) {
      this.errors = [];
      this.errorPassword = [];

      if (!this.email) {
        this.errors.push("Email required.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Valid email required.");
      } else if (!this.password) {
        this.errorPassword.push("Password required.");
      } else {
        this.showLoader = true;
        this.login();
      }

      if (!this.errors.length) {
        return true;
      }

      e.preventDefault();
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    login() {
      const data = {
        username: this.email,
        password: this.password,
      };
      this.$store.dispatch("actLogin", data);
    },
    logVal() {
      this.showLoader = false;
      this.showWarning = true;
      setTimeout(() => (this.showWarning = false), 3000);
    },
  },
  watch: {
    handleLoginError(val) {
      if (val.data == true) {
        this.logVal();
      }
    },
  },
};
</script>

<style>
</style>